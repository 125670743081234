import React from 'react'
import "../App.css";
import Navbar from "../components/Navbar"
import Footer from '../components/Footer';
// import history from '../history.js'
import axios from 'axios'
import config from '../config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment'

library.add(fas)

class Stream extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      channels: []
    }
    
    
  }
  componentDidMount = () => {
    this.feedChannel()
  }

  feedChannel = () => {
    axios.post(config.host + '/api/v1/live/stream' , {
      userid: localStorage.StudioUserID
    }).then(res => {
      // console.log(res.data.result);
      this.setState({
        channels: res.data.result
      })
      // console.log(res.data.result);
    }).catch(err => {
      console.log(err);
    })
  }

  chooseCh(e, data) {
    this.props.history.push("/studio/" + data);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // console.log(data);
  }


  render() {
    return (
			<>
      <Navbar />
			<div className="container" style={{minHeight: '80vh'}}>
        
          <div className="row">
          {this.state.channels.map((item, index) =>
            <div className="col-md-4">
            <div className="card" key={index} style={{marginTop: 0+'px'}}>
          <div className="card-header">
            <h3 className="card-title">{item.name}</h3>
          </div>
          <div className="card-body">
          <div className="table-responsive" style={{paddingBottom: 0+'px'}}>
						<table className="table card-table table-vcenter">
							<thead>
								<tr>
									{/* <th>Descriptions</th> */}
									{/* <th class="text-center" width="100">Recorder</th> */}
									<th className="text-center" width="100">Source</th>
                  <th className="text-center" width="100">Recorder</th>
                  {/* <th className="text-center" width="150">Last Update</th> */}
									<th className="text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									{/* <td>{item.desc}</td> */}
									{/* <td class="text-center">
										<span class="tag tag-green" ng-show="item.option == 'auto'">Auto</span>
										<span class="tag tag-primary" ng-show="item.option == 'manual'">Manual</span>
										<span class="tag tag-red" ng-show="item.option == 'none'">Off</span>
									</td> */}
									<td className="text-center">
										<div className={item.isconnect === 'true' ? 'd-block' : 'd-none'}><span style={{color: 'green'}}><FontAwesomeIcon icon={['fas', 'video']} /></span></div>
										<div className={item.isconnect === 'false' ? 'd-block' : 'd-none'}><span style={{color: 'red'}}><FontAwesomeIcon icon={['fas', 'video']} /></span></div>
									</td>
                  <td className="text-center">
										<div className={item.isrecording === 'true' ? 'd-block' : 'd-none'}><blink><span className="status-icon bg-danger" ></span></blink></div>
										<div className={item.isrecording === 'false' ? 'd-block' : 'd-none'}><span style={{color: '#808080'}}><FontAwesomeIcon icon={['fas', 'circle']} /></span></div>
									</td>
                  {/* <td className="text-center">{moment(item.updatedAt).format('yyyy-MM-DD HH:mm')}</td> */}
									<td className="text-center">
                    <button className="btn btn-dark" onClick={e => this.chooseCh(e, item.id)}>Studio</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
          </div>
        </div>
            </div>
            )}
          </div>
        
        
        {/* <div className="card" style={{marginTop: 20+'px', cursor: 'pointer'}}>
          <p>Channel 2</p>
        </div> */}
      </div>
      <Footer />
			</>
    );
  }
}

export default Stream

