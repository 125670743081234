import React, { useState,useEffect } from 'react'
import "../App.css";
import socketIOClient from 'socket.io-client'
import VideoPlayer from '../components/TestPlayer'

import axios from 'axios';
import config from '../config'

// import 'videojs-settings-menu'



const socket = socketIOClient("https://socket-io.i-iptv.com");
// const socket = new socketIOClient("http://socket-io.i-iptv.com");

const Test = () => {
	const [isConnected, setIsConnected] = useState(socket.connected);
    const [lastMessage, setLastMessage] = useState(null);

  useEffect(() => {
    
    // socket.on('disconnect', () => {
    //   setIsConnected(false);
    //   console.log('disconnected');
    // });
    // socket.on('message', data => {
    //   setLastMessage(data);
    // });
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('message');
    };
  });

  const sendMessage = () => {
    socket.emit('hello!');
  }

    const Hello = () => (
        <div>Hello world</div>
    )

    const videoJsOptions = {
      autoplay: true,
      muted: true,
      controls: true,
      // 'playbackRates': [0.5, 1, 1.5, 2],
      // controlBar: {
      //   children: {
          
      //     'playToggle':{},
      //     'muteToggle':{},
      //     'volumeControl':{},
      //     'currentTimeDisplay':{},
      //     'timeDivider':{},
      //     'durationDisplay':{},
      //     'liveDisplay':{},
  
      //     'flexibleWidthSpacer':{},
      //     'progressControl':{},
          
  
      //     'settingsMenuButton': {
      //       entries : [
      //         'playbackRateMenuButton'
      //       ]
      //     },
      //     'fullscreenToggle':{}
      //   }
      // },
      // controlBar: {
      //   volumePanel: {
      //     inline: false
      //   }
      // },
      // fluid: true,
      responsive: true,
      sources: [
        {
          src: 'https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8',
        },
      ],
    };

    return (
        <>
            {/* <div className="App">
      <header className="App-header">
        <p>Connected: { '' + isConnected }</p>
        <p>Last message: { lastMessage || '-' }</p>
        <button onClick={ sendMessage }>Say hello!</button>
      </header>
    </div> */}
    <div className="">
				{/* regkljerklgjreklgjkrejglkerjgk */}
				<VideoPlayer id="video-area" className="" type="vod" {... videoJsOptions} />
			</div>
        </>
    )
}

export default Test
