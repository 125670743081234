import React from 'react'
import "../App.css";
import "../custom.css";
import "../dashboard.css";
import { MDBBtn } from 'mdb-react-ui-kit';
import logo from '../images/logo_001.png'
import axios from 'axios';
import config from '../config'
// import history from '../history.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const optionToast = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: false,
	draggable: true,
	progress: undefined,
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.Login = this.Login.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.state = {
      email: '',
      password: '',
      alertEmail: ''
    }
  }

  componentDidMount() {
    this.checkSession()
    // console.log(config.host);
    // this.checkLogin();
  }

  handleChangeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  handleChangePassword(event) {
    this.setState({
      password: event.target.value
    });
  }
  
  Login() {
    axios.post(config.host + '/api/v1/auth/login' , {
      email: this.state.email,
      password: this.state.password
    }).then(res => {
      if (res.status === 200) {
        localStorage.StudioUserToken = res.data.token
        localStorage.StudioUserID = res.data.user.auth.id
        this.props.history.push("/home");
      }
    }).catch(err => {
      this.setState({
        alertEmail: 'Invalid email or password.'
      })
      
      setTimeout(() => {
        this.setState({
          alertEmail: ''
        })
      }, 4000)
      // toast.error('Invalid email or password !', optionToast);
    })
    // if (localStorage.auth === "true") {
    //   this.props.history.push("/home");
    // } 
  }

  checkSession = () => {
    if (!localStorage.StudioUserID) {
      this.props.history.push("/");
    } else {
      this.props.history.push("/home");
    }
    // console.log(localStorage.StudioUserID);
  }


  render() {
    return (
			<>
      <ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
			/>
				<div className="bg">
        <div className="form-login text-center">
          <img src={logo} alt="Logo" className="logo-login" />
          <div className="form-input ">
            
            <div className="card">
              <div className="card-body">
                <div className="card-title">Log in to your account</div>
                <span style={{color: 'red', fontSize: '14px', fontWeight: '600'}}>{this.state.alertEmail}</span>
                <label className="form-label text-left">Username</label>
								<input type="email" className="form-control" id="email" placeholder="Enter email" value={this.state.email} onChange={this.handleChangeEmail}></input>
                <br/>
                <label className="form-label text-left">Password</label>
                <input type="password" id="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.handleChangePassword}></input>
						    <br/>
                <div className="form-group">
									<label className="custom-control custom-checkbox">
	                  <input type="checkbox" className="custom-control-input" id="remember_me"/>
	                  <span className="custom-control-label float-left">Keep me logged in</span>
	                </label>
							  </div>

						    <MDBBtn className='' style={{width: 100+'%'}} onClick={this.Login} color='primary'>Log in</MDBBtn>
              </div>
              
            </div>
						
					</div>
        </div>
					
				</div>
			</>
    );
  }
}

export default Login

