import React from 'react'
import "../App.css";
// import history from '../history.js'

class NotFoundPage extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount = () => {
    
  }


  render() {
    return (
			<>
			<h1>NotFoundPage</h1>
			</>
    );
  }
}

export default NotFoundPage

