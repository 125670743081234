import React from 'react'
import "../App.css";
import Navbar from "../components/Navbar"
import Footer from '../components/Footer';
// import history from '../history.js'
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  UncontrolledDropdown,
	Row,
	Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import config from '../config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment'
// import VideoPlayer from '../assets/video-js/video'
import VideoPlayer from '../components/Video'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import poster from '../images/default-image.png'
import video from '../assets/movies'

library.add(fas)

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

const optionToast = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
}


class Video extends React.Component {
  constructor(props) {
    super(props);

		this.handleChangeEmbed = this.handleChangeEmbed.bind(this);
		this.handleChangeTitle = this.handleChangeTitle.bind(this);
		this.handleChangeDesc = this.handleChangeDesc.bind(this);
		this.handleChangeEnable = this.handleChangeEnable.bind(this);
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
		this.handleChangeThumbnails = this.handleChangeThumbnails.bind(this);

		this.state = {
			video: [],
			index: 1,
			video_id: '',
			embed: '',
			desc: '',
			title: '',
			enable: '',
			modalPreview: false,
			modalEmbed: false,
			modalTranscode: false,
			modalEdit: false,
			modalDelete: false,
			modalPreviewErr: false,
			modalEditErr: false,
			stream_url: '',
			resetPaginationToggle: null,
			filterText: null,
			search: '',
			default: true,
			thumbnail: '',
			thumbnailFile: '',
			thumbnails: [],
			imgSrc: null
		}
  }

	componentDidMount = () => {
		this.feedVideo()
		// this.testImage("http://foo.com/bar.jpg");
	}

	togglePreview = (e, id, status) => {
		this.setState({
			video_id: id
		})

		if (id) {
			axios.post(config.host + '/api/v1/video/record/preview' , {
				id: id,
				userid: localStorage.StudioUserID
			}).then(res => {
				
				this.setState({
					stream_url: res.data.stream_url
				})
				console.log(this.state.stream_url);
	
				// localStorage.setItem('StudioStreamUrl', this.state.stream_url);
			}).catch(err => {
	
			})
		}

		if (!status || status === 'complete') {
			this.setState({
				modalPreview: !this.state.modalPreview
			})
		} else {
			this.setState({
				modalPreviewErr: !this.state.modalPreviewErr
		})

		
		
		}
    
  }

	togglePreviewErr = () => {
    this.setState({
      modalPreviewErr: !this.state.modalPreviewErr
    })
  }
	toggleEmbed = (e, id) => {
		// console.log(id);
		axios.post(config.host + '/api/v1/video/record/detail' , {
			id: id,
			userid: localStorage.StudioUserID
		}).then(res => {
			// console.log(res.data.result.embed_player);
			this.setState({
				embed: res.data.result.embed_player
			})
		}).catch(err => {

		})
    this.setState({
      modalEmbed: !this.state.modalEmbed
    })
  }

	toggleTranscode = () => {
    this.setState({
      modalTranscode: !this.state.modalTranscode
    })
  }

	toggleEdit = (e, id) => {
    this.setState({
      modalEdit: !this.state.modalEdit,
			video_id: id,
			imgSrc: null
    })

		axios.post(config.host + '/api/v1/video/record/detail' , {
			id: id,
			userid: localStorage.StudioUserID
		}).then(res => {
			console.log(res.data.result);
			this.setState({
				title: res.data.result.title,
				desc: res.data.result.desc,
				enable: res.data.result.enable,
				thumbnail: res.data.result.thumbnail,
				thumbnails: res.data.result.thumbnail_array
			})
		}).catch(err => {

		})
  }

	toggleDelete = (e, id) => {
    this.setState({
      modalDelete: !this.state.modalDelete,
			video_id: id
    })

		axios.post(config.host + '/api/v1/video/record/detail' , {
			id: id,
			userid: localStorage.StudioUserID
		}).then(res => {
			// console.log(res.data.result);
			this.setState({
				title: res.data.result.title
			})
		}).catch(err => {
			
		})
  }

	handleChangeEmbed(event) {
    this.setState({
      embed: event.target.value
    });
  }

	handleChangeDesc(event) {
    this.setState({
      desc: event.target.value
    });
  }

	handleChangeTitle(event) {
    this.setState({
      title: event.target.value
    });
  }

	handleChangeEnable(event) {
    this.setState({
      enable: event.target.value
    });
  }

	handleChangeSearch(event) {
		this.setState({
      search: event.target.value
    });
	}

	getInitialState(){
		return{file: []}
	}

	handleChangeThumbnails() {
		var file = this.refs.file.files[0];
  	var reader = new FileReader();
  	var url = reader.readAsDataURL(file);

		reader.onloadend = function (e) {
      this.setState({
          imgSrc: reader.result
      })
    }.bind(this);
  	// console.log(reader) // Would see a path?
		// console.log(this.state.imgSrc);
		// console.log(this.refs.file.files);
	}

	feedVideo = () => {
		axios.post(config.host + '/api/v1/video/record' , {
			keyword: this.state.search,
			userid: localStorage.StudioUserID
		}).then(res => {
			console.log(res.data.result);
			this.setState({
				video: res.data.result
			})
		}).catch(err => {

		})
	}

	

	embedCopy = () => {
		toast.success('Copied to clipboard !', optionToast);
		var copyText = document.getElementById('embed');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
	}

	UpdateVideo = () => {
		this.updateThumbnail()
		// console.log(this.state.video_id, localStorage.StudioUserID, this.state.title, this.state.desc, this.state.enable);
		axios.post(config.host + '/api/v1/video/record/update' , {
			id: this.state.video_id,
			userid: localStorage.StudioUserID,
			title: this.state.title,
			desc: this.state.desc,
			enable: this.state.enable
		}).then(res => {
			toast.success('Update video successfully !', optionToast);
			this.setState({
				modalEdit: !this.state.modalEdit
			})
			this.feedVideo()
		}).catch(err => {

		})
	}

	DeleteVideo = () => {
		axios.post(config.host + '/api/v1/video/record/delete' , {
			id: this.state.video_id,
			userid: localStorage.StudioUserID
		}).then(res => {
			toast.success('Delete video successfully !', optionToast);
			this.setState({
				modalDelete: !this.state.modalDelete
			})
			this.feedVideo()
		}).catch(err => {

		})
	}

	searchVideo = (e, data) => {
		axios.post(config.host + '/api/v1/video/record' , {
			keyword: data,
			userid: localStorage.StudioUserID
		}).then(res => {
			this.setState({
				video: res.data.result
			})
		}).catch(err => {

		})
	}

	filterStatus = (status) => {
		if (status === 'success') {
			return <span>ready</span>
		} else {
			return <span>{status}</span>
		}
	}

	chooseThumbnail = (e, data) => {
		this.setState({
			thumbnail: data.url,
			thumbnailFile: data.name
		})
	}

	updateThumbnail = () => {

		if (this.state.imgSrc) {
			var getTitle = this.state.title
			var title = getTitle.split(" ")
			var timestamp = title[0] + '-' + moment().valueOf() + '.jpg'

			const FormData = require('form-data');
			let data = new FormData();
			data.append('id', this.state.video_id);
			data.append('userid', localStorage.StudioUserID);
			data.append('filename', timestamp);
			data.append('file', this.refs.file.files[0]);

			let options = {
				method: 'POST',
				url: config.host + '/api/v1/video/record/thumbnail/upload',
				headers: {
						'Content-Type': 'multipart/form-data;'
				},
				data
		};

		axios(options).then(response => {
      console.log(response);
    }).catch(error => {
			console.log(error);
		});
		} else {
			axios.post(config.host + '/api/v1/video/record/thumbnail/selected' , {
				id: this.state.video_id,
				userid: localStorage.StudioUserID,
				thumbnail_selected: this.state.thumbnailFile
			}).then(res => {
				// this.feedVideo()
			}).catch(err => {

			})
		}
		
	}


  render() {
		const columns = [
			{
				name: "ID",
				selector: "no",
				sortable: true,
				width: '60px',
				// cell: (row, index) => index+1
			},
			{
				name: "Preview",
				// selector: "thumb",
				// sortable: true
				width: '110px',
				cell: row => 	<div className="poster-preview" style={{cursor: 'pointer'}} onClick={e => this.togglePreview(e, row.id, row.status)}>
												<img src={row.thumbnail} style={{margin: 5+'px'}} onError={(e)=>{e.target.onerror = null; e.target.src=poster}}/>
												<div className="play-overlay">
													<i className="fa fa-play"></i>
												</div>
											</div>
			},
			{
				name: 'Title',
				selector: "title",
				width: '350px',
				style: {
					paddingLeft: '0px'
				},
				sortable: true
			},
			{
				name: "Size",
				selector: "size",
				width: '100px',
				sortable: true
			},
			{
				name: "Duration",
				selector: "duration",
				sortable: true,
				width: '100px',
			},
			{
				name: "Multi-Bitrate",
				selector: "process",
				width: '118px',
				sortable: true,
				cell: row => this.filterStatus(row.process)
			},
			{
				name: "Status",
				selector: "status",
				sortable: true,
				width: '100px',
			},
			{
				name: "Last Update",
				// selector: "updatedAt",
				width: '140px',
				sortable: true,
				cell: row => <span>{moment(row.updatedAt).format('yyyy-MM-DD HH:mm')}</span>
			},
			{
				name: "",
				width: '40px',
				selector: "action",
				cell: row => <UncontrolledDropdown setActiveFromChild>
											<DropdownToggle tag="a" className="nav-link">
												<span className="text-default"><FontAwesomeIcon icon={['fas', 'ellipsis-v']} /></span>
											</DropdownToggle>
											<DropdownMenu className="drop-table">
												{/* <DropdownItem onClick={e => this.toggleEmbed(e, row.id)}><FontAwesomeIcon icon={['fas', 'play']} /> Embed Player</DropdownItem> */}
												{/* <DropdownItem onClick={e => this.switchModal(e, 'transcode', row.id)}><FontAwesomeIcon icon={['fas', 'cog']} /> Transcoder</DropdownItem> */}
												{row.process === 'success' ? <DropdownItem onClick={e => this.toggleEmbed(e, row.id)}><FontAwesomeIcon icon={['fas', 'play']} /> Embed Player</DropdownItem> : ''}
												{row.process === 'success' ? <DropdownItem onClick={e => this.toggleEdit(e, row.id)}><FontAwesomeIcon icon={['fas', 'edit']} /> Edit</DropdownItem> : ''}
												{/* <DropdownItem onClick={e => this.toggleEdit(e, row.id)}><FontAwesomeIcon icon={['fas', 'edit']} /> Edit</DropdownItem> */}
												<DropdownItem onClick={e => this.toggleDelete(e, row.id)}><FontAwesomeIcon icon={['fas', 'trash']} /> Delete</DropdownItem>
											</DropdownMenu>
										</UncontrolledDropdown>,
				// right: true
				style: {
					position: 'absolute',
					right: '0'
				}
			}
		];

		const videoJsOptions = {
			autoplay: true,
			// muted: true,
			controls: true,
			controlBar: {
				volumePanel: {
					inline: true
				}
			},
			fluid: true,
			responsive: true,
			sources: [
				{
					src: 'https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8',
				},
			],
		};

    return (
			<>
      <Navbar />
			<ToastContainer
				position="top-right"
				autoClose={50000000000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
      <div className="container" style={{minHeight: '80vh'}}>
				<div className="card" style={{marginTop: 20+'px', position: 'relative'}}>
					<div className="card-header">
					<h3 className="card-title">Video Library</h3>
					</div>
					<div className="card-body">
					<div className="form-group" style={{width:30+'%', position: 'absolute', right: 15+'px'}}>
							<div className="flex-container">
								<input type="text" className="form-control" value={this.state.search} onChange={this.handleChangeSearch} placeholder="Enter keyword" style={{marginRight: 10+'px'}}/>
								<button className="btn btn-primary" onClick={e => this.searchVideo(e, this.state.search)}>Search</button>
							</div>
							
						</div>
						
						<div style={{marginTop: 50+'px'}}>
						<DataTable 
					className="dataTables_wrapper"
        columns={columns}
        data={this.state.video}
    		// theme="solarized"
				// customStyles={customStyles}
				// noDataComponent={
				// 	<DataTable className="dataTables_wrapper" noHeader={true}
				// 	width="100%" pagination
				// 	striped/>
				// }
				pagination
				striped
				persistTableHead={true}
				noHeader={true}
				width="100%"
      />
						</div>
					
					</div>
				</div>  

				{/* modal preview */}
				<Modal isOpen={this.state.modalPreview} centered backdrop="static" size="lg">
        	<ModalHeader toggle={this.togglePreview}>Preview</ModalHeader>
        	<ModalBody>
						{/* <ReactHlsPlayer
              src={this.state.stream_url}
              autoPlay={true}
              controls={true}
              width="100%"
              height="auto"
            /> */}
						{/* <Player>
              <HLSSource isVideoChild id={this.state.video_id} typeUrl="Vod"/>
            </Player> */}
						<VideoPlayer type="vod" id={this.state.video_id} {... videoJsOptions} />
        	</ModalBody>
				</Modal>

				<Modal isOpen={this.state.modalPreviewErr} toggle={this.togglePreviewErr} centered backdrop="static" size="md">
        	<ModalHeader toggle={this.togglePreviewErr}>System Info</ModalHeader>
        	<ModalBody>
						<p>The system is currently video recording.</p>
        	</ModalBody>
				</Modal>

				{/* <Modal isOpen={this.state.modalEditErr} toggle={this.toggleEditErr} centered backdrop="static" size="md">
        	<ModalHeader toggle={this.toggleEditErr}>System Info</ModalHeader>
        	<ModalBody>
						<p>The system is currently video recording.</p>
        	</ModalBody>
				</Modal> */}

				{/* modal embed player */}
				<Modal isOpen={this.state.modalEmbed} toggle={this.toggleEmbed} centered backdrop="static">
        	<ModalHeader toggle={this.toggleEmbed}>Embed Player</ModalHeader>
        	<ModalBody>
						<div className="form-group">
							<textarea id="embed" rows="5" type="text" className="form-control" value={this.state.embed} onChange={this.handleChangeEmbed}></textarea>
							<div style={{float: 'right'}}><button type="button" className="btn btn-light" onClick={this.embedCopy}
                  style={{fontSize: 11+'px'}}>Copy</button></div>
						</div>

						
        	</ModalBody>
				</Modal>

				{/* modal edit */}
				<Modal isOpen={this.state.modalEdit} centered backdrop="static" size="lg">
        	<ModalHeader toggle={e => this.setState({modalEdit: false})}>Edit {'>'} {this.state.title}</ModalHeader>
        	<ModalBody>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<div className="">
										<div className="">
											<div className="form-group">
												<label className="form-label text-left">Title</label>
												<input type="text" className="form-control" id="title" placeholder="title" value={this.state.title} onChange={this.handleChangeTitle}></input>
											</div>
											<div className="form-group">
												<label className="form-label text-left">Description</label>
												<textarea id="desc" rows="5" type="text" className="form-control" value={this.state.desc} onChange={this.handleChangeDesc}></textarea>
											</div>
											<div className="form-group">
												<div className="form-label">Public</div>
													<div className="custom-controls-stacked">
														<label className="custom-control custom-radio custom-control-inline">
															<input type="radio" className="custom-control-input" value="true" checked={this.state.enable === "true"} onChange={this.handleChangeEnable} />
															<span className="custom-control-label">Enable</span>
														</label>
														<label className="custom-control custom-radio custom-control-inline">
															<input type="radio" className="custom-control-input" value="false" checked={this.state.enable === "false"} onChange={this.handleChangeEnable} />
															<span className="custom-control-label">Disable</span>
														</label>
													</div>
											</div>
										</div>
									</div>
							
									{/* <textarea rows="5" type="text" className="form-control"></textarea>
									<div style={{float: 'right'}}><button type="button" className="btn btn-light"
                  		style={{fontSize: 11+'px'}}>Copy</button></div> */}
								</div>
							</div>

							<div className="col-md-6">
								<div className="form-group">
									<label className="form-label text-left">Thumbnails</label>
									{!this.state.imgSrc ? <img src={this.state.thumbnail} style={{width: '230px'}} /> : <img src={this.state.imgSrc} style={{width: '230px'}} />}
								</div>
								<div className="form-group">
									<form>
										<input ref="file" 
        							type="file" 
        							name="user[image]"
        							onChange={this.handleChangeThumbnails} />
									</form>
								</div>

								<div className="row">
									{this.state.thumbnails.map((item, index) => 
										<div className="col-md-4" style={{marginBottom:'10px'}} onClick={e => this.chooseThumbnail(e, item)}>
											<img src={item.url} style={{width: '120px'}} className={item.url === this.state.thumbnail ? 'btn-thumbnail active-thumbnail ' : 'btn-thumbnail'}/>
										</div>
									)}
									<div className="col-md-4">
										<img src={this.state.imgSrc} style={{width: '120px'}} className="btn-thumbnail" />
									</div>
									
								</div>
							</div>
						
						</div>
						

						
        	</ModalBody>
					<ModalFooter>
						<div>
							<button className="btn btn-primary" onClick={this.UpdateVideo} style={{margin: 10+'px'}}>Update</button>
							<button className="btn btn-light" onClick={e => this.setState({modalEdit: false})} style={{margin: 10+'px'}}>Close</button>
						</div>
					</ModalFooter>
				</Modal>

				{/* modal delete */}
				<Modal isOpen={this.state.modalDelete} toggle={this.toggleDelete} centered backdrop="static">
        	<ModalHeader toggle={this.toggleDelete}>Delete {'>'} {this.state.title}</ModalHeader>
        	<ModalBody>
						<div className="form-group text-center">
							<h5>Are you sure ?</h5>
							<button className="btn btn-danger" onClick={this.DeleteVideo} style={{margin: 10+'px'}}>Delete</button>
							<button className="btn btn-light" onClick={this.toggleDelete} style={{margin: 10+'px'}}>Close</button>
						</div>

						
        	</ModalBody>
				</Modal>
			
      </div>
			<Footer />
			</>
    );
  }
}

export default Video

