import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  UncontrolledDropdown,
  NavbarText
} from 'reactstrap';
import logo from "../images/logo_001.png"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import config from '../config'
import Breadcrumb from './Breadcrumb'

// import { useHistory } from "react-router-dom";

library.add(fas)


class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    // this.checkSession = this.checkSession.bind(this);
    this.state = {
      isOpen: false,
      pathname: '',
      dropdownOpen: false,
      setDropdownOpen: false,
      email: '',
      url: [],
      id: ''
    };
  }
  
  

  componentDidMount = () => {
    this.subPath(window.location.pathname)
    this.checkSession()
    this.feedAccount()
    this.checkUrl(window.location.pathname)
  }

  checkUrl = (url) => {
    var urlSplit = url.split("/")
    if (urlSplit[1] === 'home') {
      this.setState({
        url: ['Home']
      })
    } else if (urlSplit[1] === 'live-streams') {
      this.setState({
        url: ['Home', 'Live Streams']
      })
    } else if (urlSplit[1] === 'videos') {
      this.setState({
        url: ['Home', 'Videos']
      })
    } else if (urlSplit[1] === 'profile') {
      this.setState({
        url: ['Home', 'Profile']
      })
    } else if (urlSplit[1] === 'monitoring') {
      this.setState({
        url: ['Home', 'Monitoring']
      })
    } else if (urlSplit[1] === 'studio') {
      axios.post(config.host + '/api/v1/live/stream/detail' , {
        id: urlSplit[2],
        userid: localStorage.StudioUserID
      }).then(res => {
        // console.log(res.data.result.name);
        var nameCh = res.data.result.name
        var idCh = res.data.result.id
        this.setState({
          url: ['Home', 'Live Streams', nameCh],
          id: idCh
        })
      }).catch(err => {
      
      })
      
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  
  subPath = (data) => {
    this.setState({
      pathname: data.substr(0, 7)
    })
  }

  checkSession = () => {
    if (!localStorage.StudioUserID) {
      window.location.assign("/");
    }
  }

  Logout = () => {
    axios.post(config.host + '/api/v1/auth/logout').then(res => {
      localStorage.StudioUserToken = ''
      localStorage.StudioUserID = ''
      this.checkSession()
    }).catch(err => {

    })
    
  }

  feedAccount = () => {
    axios.post(config.host + '/api/v1/account', {
      id: localStorage.StudioUserID
    }).then(res => {
      this.setState({
        email: res.data.result[0].email
      })
    }).catch(err => {

    })
  }

  render() {
    
    return(
      <div>
      <Navbar color="light" light expand="md" >
        <NavbarBrand href="/home">
          <img src={logo} alt="" style={{width: 60+'px'}} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/home" className={this.state.pathname === '/home' ? "active-nav" : ""}><FontAwesomeIcon icon={['fas', 'home']} style={{marginRight: 5+'px'}} />Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/live-streams" className={this.state.pathname === '/live-s' || this.state.pathname === '/studio' ? "active-nav" : ""}><FontAwesomeIcon icon={['fas', 'video']} style={{marginRight: 5+'px'}} />Live Streams</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/videos" className={this.state.pathname === '/videos'  ? "active-nav" : ""}><FontAwesomeIcon icon={['fas', 'play-circle']} style={{marginRight: 5+'px'}} />Videos</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/monitoring" className={this.state.pathname === '/monito'  ? "active-nav" : ""}><FontAwesomeIcon icon={['fas', 'chart-line']} style={{marginRight: 5+'px'}} />Monitoring</NavLink>
            </NavItem>
          </Nav>
          <UncontrolledDropdown setActiveFromChild>
          <DropdownToggle tag="a" className="nav-link" caret>
            <span className="text-default"><FontAwesomeIcon icon={['fas', 'user']} /> {this.state.email}</span>
          </DropdownToggle>
          <DropdownMenu className="drop-profile">
            <DropdownItem href="/profile">Profile</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.Logout}>Logout</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        </Collapse>
      </Navbar>
      <Breadcrumb url={this.state.url} id={this.state.id} />
    </div>
    );
  }
}

export default Example;