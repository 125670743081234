import React from "react";
import { MDBContainer } from "mdbreact";
export default function Footer() {
  return (
    			<div className="footer-copyright text-center py-3" style={{backgroundColor: '#fff', borderTop: '1px solid rgba(0, 40, 100, 0.12)'}}>
            <MDBContainer fluid style={{color: '#9aa0ac'}}>
              Copyright{" "}&copy; {new Date().getFullYear()}
              <span> i-IPTV Co.,ltd. v1.0.0</span>
            </MDBContainer>
          </div>
  );
}
