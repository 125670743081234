import React, { PureComponent } from "react";
import Highcharts from "highcharts";
// import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import axios from 'axios';
import config from '../config'
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

export default class Chart extends PureComponent {
  instance;

  constructor(props) {
    super(props);
    
    this.state = {
      channels: [],
      startDate: moment(moment().startOf('day').format('YYYY-MM-DD HH:mm'))._d,
      endDate: moment(moment().endOf('day').format('YYYY-MM-DD HH:mm'))._d,
      range: '',
      channel: ''
    }
    
    
  }

  

  // options = {
  //   chart: {
  //     type: "line"
  //   },
  //   title: {
  //     text: "Bar Chart"
  //   },
  //   series: [
  //     {
  //       data: [1, 3, 4, 10, 9]
  //     }
  //   ]
  // };

  
  componentDidMount() {
    // console.log(moment(1625590800000).format('hh:mm'));
    // console.log(moment(1625677199999).format('hh:mm'));
    // console.log(this.state.startDate);
    this.feedChannel()
    if (!this.state.channel || this.state.range) {
      axios.post(config.host + '/api/v1/live/stream' , {
        userid: localStorage.StudioUserID
      }).then(res => {
        this.setState({
          channel: res.data.result[0].stream_output
        })
        axios.post('https://api.i-iptv.com/api/v1/monitor/overview' , {
          form: parseFloat(moment.unix(this.state.startDate).format('X')),
          to: parseFloat(moment.unix(this.state.endDate).format('X')),
          range: this.state.range,
          streamname: [res.data.result[0].stream_output]
        }).then(res => {
          this.instance = Highcharts.chart("dummy-id", {
          chart: {
            type: "line"
          },
          title: {
            text: "Connection"
          },
          series: [{
            name: "Connection",
            color: "#ffb247",
            data: [],
          }],
          yAxis: {
            title: {
              text: 'Connection (ccu)'
            },
            alternateGridColor: null,
            labels: {
              format: '{value}'
            },
            allowDecimals: false
          },
          xAxis: {
            borderWidth: 0.1,
            gridLineWidth: 1,
            minorGridLineWidth: 1,
            type: 'datetime',
            dateTimeLabelFormats: {
              minute: '%H:%M',
              hour: '%H:%M',
              day: '%e. %b',
              week: '%e. %b',
              month: '%b \'%y',
              year: '%Y'
            }
          },
          options: {
            title: {
              display: true,
              text: 'Connection'
            },
            legend: {
              labels: {
                fontColor: 'rgba(255,255,255,0)'
              }
            },
            elements: {
              point: {
                radius: 0
              },
              line: {
                tension: 0.0001
              }
            },
            scales: {
              yAxes: [{
                display: true,
                gridLines: {
                  display: true
                },
                ticks: {
                  beginAtZero: true,
                    min: 0,
                    max: 10,
                }
              }],
            }
          }
      });
      // console.log(res);
      // console.log(this.instance);
      // console.log(res.data.ccu[0].data);
      // this.instance.xAxis[0].setCategories(res.data.ccu[0].name)
      this.instance.series[0].setData(res.data.ccu[0].data)
      }).catch(err => {

      })
        // this.setState({
        //   channel: res.data.result[0].stream_output
        // })
      }).catch(err => {
        // console.log(err);
      })
      

      
    }
    
  }

  feedChannel = () => {
    axios.post(config.host + '/api/v1/live/stream' , {
      userid: localStorage.StudioUserID
    }).then(res => {
      this.setState({
        channels: res.data.result
      })
      // console.log(res.data.result);
    }).catch(err => {
      // console.log(err);
    })
  }

  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  }

  handleChangeRange = (event) => {
    this.setState({
      range: event.target.value
    });

    switch (event.target.value) {
      case 'today':
        this.setState({
          startDate: moment(moment().startOf('day').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().endOf('day').format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'lasthour':
        this.setState({
          startDate: moment(moment().subtract(1, 'hours').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'last4hours':
        this.setState({
          startDate: moment(moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'last8hours':
        this.setState({
          startDate: moment(moment().subtract(8, 'hours').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'last12hours':
        this.setState({
          startDate: moment(moment().subtract(12, 'hours').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'yesterday':
        this.setState({
          startDate: moment(moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'last7days':
        this.setState({
          startDate: moment(moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().endOf('day').format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'thismonth':
        this.setState({
          startDate: moment(moment().startOf('month').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().endOf('month').format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'lastmonth':
        this.setState({
          startDate: moment(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'thisyear':
        this.setState({
          startDate: moment(moment().startOf('year').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().endOf('year').format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      case 'lastyear':
        this.setState({
          startDate: moment(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD HH:mm'))._d,
          endDate: moment(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD HH:mm'))._d,
        })
        break;
      default:
    }
  }

  handleChangeChannel = (event) => {
    this.setState({
      channel: event.target.value
    });
  }



  searchData = () => {
    axios.post('https://api.i-iptv.com/api/v1/monitor/overview' , {
          form: parseFloat(moment.unix(this.state.startDate).format('X')),
          to: parseFloat(moment.unix(this.state.endDate).format('X')),
          range: this.state.range,
          streamname: [this.state.channel]
        }).then(res => {
          this.instance = Highcharts.chart("dummy-id", {
          chart: {
            type: "line"
          },
          title: {
            text: "Connection"
          },
          series: [{
            name: "Connection",
            color: "#ffb247",
            data: [],
          }],
          yAxis: {
            title: {
              text: 'Connection (ccu)'
            },
            alternateGridColor: null,
            labels: {
              format: '{value}'
            },
            allowDecimals: false
          },
          xAxis: {
            borderWidth: 0.1,
            gridLineWidth: 1,
            minorGridLineWidth: 1,
            type: 'datetime',
            dateTimeLabelFormats: {
              minute: '%H:%M',
              hour: '%H:%M',
              day: '%e. %b',
              week: '%e. %b',
              month: '%b \'%y',
              year: '%Y'
            }
          },
          options: {
            title: {
              display: true,
              text: 'Connection'
            },
            legend: {
              labels: {
                fontColor: 'rgba(255,255,255,0)'
              }
            },
            elements: {
              point: {
                radius: 0
              },
              line: {
                tension: 0.0001
              }
            },
            scales: {
              yAxes: [{
                display: true,
                gridLines: {
                  display: true
                },
                ticks: {
                  beginAtZero: true,
                    min: 0,
                    max: 10,
                }
              }],
            }
          }
      });
      // console.log(res);
      // console.log(this.instance);
      // console.log(res.data.ccu[0].data);
      // this.instance.xAxis[0].setCategories(res.data.ccu[0].name)
      this.instance.series[0].setData(res.data.ccu[0].data)
      }).catch(err => {

      })
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <div className="form-label" >Channels: </div>
              <select className="form-control" onChange={this.handleChangeChannel}>
                {this.state.channels.map((item, index) => 
                  <option value={item.stream_output} >{item.name}</option>
                )}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <div className="form-label" >Date Range: </div>
              <select className="form-control" onChange={this.handleChangeRange} value={this.state.range}>
                <option value="today">Today</option>
                <option value="lasthour">Last Hour</option>
                <option value="last4hours">Last 4 Hours</option>
                <option value="last8hours">Last 8 Hours</option>
                <option value="last12hours">Last 12 Hours</option>
                <option value="yesterday">Yesterday</option>
                <option value="last7days">Last 7 Days</option>
                <option value="thismonth">This Month</option>
                <option value="lastmonth">Last Month</option>
                <option value="thisyear">This Year</option>
                <option value="lastyear">Last Year</option>
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <div className="form-label" >From: </div>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => this.setState({startDate : date})}
                  timeInputLabel="Time:"
                  showTimeInput
                  dateFormat="yyyy-MM-dd  HH:mm"
                  className="form-control"
                />
              {/* <select className="form-control">
                <option value="all">All</option>
                <option value=""></option>
              </select> */}
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <div className="form-label" >To: </div>
              <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => this.setState({endDate : date})}
                  timeInputLabel="Time:"
                  showTimeInput
                  dateFormat="yyyy-MM-dd  HH:mm"
                  className="form-control"
                />
              {/* <select className="form-control">
                <option value="all">All</option>
                <option value=""></option>
              </select> */}
            </div>
          </div>

          <div className="col-md-2">
            <br/>
            <button className="btn btn-primary" onClick={this.searchData} style={{marginTop: 4+'px'}}>Search</button>
          </div>
          
        </div>

        {/* <div className="row">
          <div className="col-md-3">
          <button className="btn btn-primary" onClick={this.searchData}>Search</button>
          </div>
        </div> */}
        <br/>
        <div id="dummy-id" />
      </div>
    );
  }
}