import React from 'react'
import "../App.css";
import Navbar from "../components/Navbar"
import Footer from '../components/Footer';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import axios from 'axios'
import config from '../config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Player } from 'video-react';
// import HLSSource from '../HLSSource';
// import '../assets/video-react.css'
// import VideoPlayer from '../assets/video-js/video'
// import VideoPlayer from '../components/Video'
import VideoPlayer from '../components/Live'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Switch } from 'react-switch-input';
import socketIOClient from 'socket.io-client'
import TestStream from '../pages/TestStream'
import ReactTooltip from 'react-tooltip';

const socket = socketIOClient("https://socket-io.i-iptv.com");


library.add(fas)

const optionToast = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
}


class Studio extends React.Component {

  
  
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalConfirm: false,
      studios: [],
      playerSource: '',
      stream_url: '',
      value: false,
      valueShow: false,
      title: '',
      desc: '',
      isrecording: false,
      isconnect: false,
      disable: false,
      viewer: 0,
      copyServerStatus: false,
      copyKeyStatus: false,
      copyUrlStatus: false,
      copyEmbedStatus: false,
      refreshStream: ''
    }


    this.handleChangeTitle = this.handleChangeTitle.bind(this);
		this.handleChangeDesc = this.handleChangeDesc.bind(this);

  }

  handleChangeSwitch = e => {
    const value = e.target.checked;
    this.setState({value});
  }

  handleChangeDesc(event) {
    this.setState({
      desc: event.target.value
    });
  }

	handleChangeTitle(event) {
    this.setState({
      title: event.target.value
    });
  }

  toggle = () => {
    this.feedStudio()
    // console.log(data);
    this.setState({
      modal: !this.state.modal,
    })
  }

  toggleConfirm = () => {
    // console.log(data);
    this.setState({
      modalConfirm: !this.state.modalConfirm,
    })
  }
  
  

  componentDidMount = () => {
    this.feedStudio()
    // this.previewLive()
    axios.post(config.host + '/api/v1/live/stream/detail' , {
      id: this.props.match.params.id,
      userid: localStorage.StudioUserID
    }).then(res => {
      // console.log(res);
      var getStreamURL = res.data.result.stream_url;
      var smilName = getStreamURL.split('/');
      var streamName = smilName[4].split('.');
      var FinalName = streamName[0].split(':');

      if (FinalName.length === 1) {
        socket.on(FinalName[0], (entry) => {
          console.log(entry.ccu);
          console.log('connected');
          if (entry.reload === 'true') {
            this.feedStudio()
          }
          
          this.setState({viewer: entry.ccu})
          this.feedStudio()
        });
        // this.setState({
        //   stream_url: FinalName[0]
        // })
      } else {
        socket.on(FinalName[1], (entry) => {
          console.log(entry.ccu);
          console.log('connected');
          if (entry.reload === 'true') {
            this.feedStudio()
          }
          
          this.setState({viewer: entry.ccu})
          this.feedStudio()
        });
        // this.setState({
        //   stream_url: FinalName[1]
        // })
      }
      
    }).catch(err => {

    })
  }

  Live = () => (
    <div className="overlay-live">
      <span className="label-live">Live</span>
    </div>
  )

  feedStudio = () => {
    axios.post(config.host + '/api/v1/live/stream/detail' , {
      id: this.props.match.params.id,
      userid: localStorage.StudioUserID
    }).then(res => {
      this.setState({
        studios: res.data.result,
        title: res.data.result.user_title,
        desc: res.data.result.user_desc
      })

      if (res.data.result.isrecording === 'true') {
        this.setState({
          isrecording: true
        })
      } else {
        this.setState({
          isrecording: false
        })
      }

      if (res.data.result.isconnect === 'true') {
        this.setState({
          disable: false,
          isconnect: true
        })
      } else {
        this.setState({
          disable: true,
          isconnect: false
        })
      }

      if (res.data.result.option === 'auto' || res.data.result.option === 'none') {
        this.setState({
          disable: true
        })
      } else {
        this.setState({
          disable: false
        })
      }

      if (res.data.result.user_views === 'true') {
        this.setState({
          value: true,
          valueShow: true
        })
      } else {
        this.setState({
          value: false,
          valueShow: false
        })
      }
    }).catch(err => {

    })
  }

  updateChannel = () => {
    axios.post(config.host + '/api/v1/live/stream/update' , {
      id: this.props.match.params.id,
      userid: localStorage.StudioUserID,
      user_title: this.state.title,
      user_desc: this.state.desc,
      user_views: this.state.value
    }).then(res => {
      if (res.data.code === '200') {
        toast.success('Update Live channel successfully !', optionToast);
      }
      this.toggle()
      this.feedStudio()
    }).catch(err => {

    })
  }

  previewLive = () => {
    axios.post(config.host + '/api/v1/live/stream/preview' , {
      id: this.props.match.params.id,
      userid: localStorage.StudioUserID
    }).then(res => {
      this.setState({
        playerSource: res.data.stream_url
      })
    }).catch(err => {

    })
  }

  recordStream = (e,data) => {
    if (data === 'true') {
      axios.post(config.host + '/api/v1/recorder/manual' , {
        id: this.props.match.params.id,
        userid: localStorage.StudioUserID,
        action: 'start'
      }).then(res => {
        if (res.data.status === 'success') {
          this.setState({
            disable: true
          })
          setTimeout(() => {
            // this.feedStudio()
            this.setState({
              isrecording: true,
              disable: false
            })
            toast.success('Start Recorder Successfully!', optionToast);
            console.log('Recording');
          }, 3000);
          // this.setState({
          //   isrecording: 'true'
          // })
          // this.feedStudio()
          // toast.success('Start Recorder Successfully!', optionToast);
          // console.log('Recording');
        }
        
      }).catch(err => {
        toast.error('Start Recorder has not working!', optionToast);
      })
      
    } else {
      this.setState({
        modalConfirm: !this.state.modalConfirm
      })
    }
  }

  stopRecord = () => {
    axios.post(config.host + '/api/v1/recorder/manual' , {
      id: this.props.match.params.id,
      userid: localStorage.StudioUserID,
      action: 'stop'
    }).then(res => {
      if (res.data.status === 'success') {
        this.toggleConfirm()
        this.setState({
          disable: true
        })
        setTimeout(() => {
          // this.feedStudio()
          this.setState({
            isrecording: false,
            disable: false
          })
          toast.success('Stop Recorder Successfully!', optionToast);
          console.log('Stop');
        }, 3000);
        // this.setState({
        //   isrecording: 'false'
        // })
        // this.feedStudio()
        // toast.success('Stop Recorder Successfully!', optionToast);
        // console.log('Stop');
      }
      
    }).catch(err => {
      toast.error('Stop Recorder has not working!', optionToast);
    })
  }

  
  
  serverCopy = () => {
		// toast.success('Copied to clipboard !', optionToast);
    this.setState({ copyServerStatus: !this.state.copyServerStatus })
		var copyText = document.getElementById('server');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setTimeout(() => {
      this.setState({ copyServerStatus: !this.state.copyServerStatus })
    }, 2000)
	}

  streamkeyCopy = () => {
		// toast.success('Copied to clipboard !', optionToast);
    this.setState({ copyKeyStatus: !this.state.copyKeyStatus })
		var copyText = document.getElementById('streamkey_copy');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setTimeout(() => {
      this.setState({ copyKeyStatus: !this.state.copyKeyStatus })
    }, 2000)
	}

  playbackCopy = () => {
		// toast.success('Copied to clipboard !', optionToast);
    this.setState({ copyUrlStatus: !this.state.copyUrlStatus })
		var copyText = document.getElementById('restream');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setTimeout(() => {
      this.setState({ copyUrlStatus: !this.state.copyUrlStatus })
    }, 2000)
	}

  embedCopy = () => {
		// toast.success('Copied to clipboard !', optionToast);
    this.setState({ copyEmbedStatus: !this.state.copyEmbedStatus })
		var copyText = document.getElementById('embed');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setTimeout(() => {
      this.setState({ copyEmbedStatus: !this.state.copyEmbedStatus })
    }, 2000)
	}

  testPlayer = () => {
    window.open(window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/test-stream/' + this.props.match.params.id);
    // const TestStream = this.props

    
  }


  render() {

    const videoJsOptions = {
			autoplay: true,
			muted: true,
			controls: true,
			// controlBar: {
			// 	volumePanel: {
			// 		inline: false
			// 	}
			// },
			fluid: true,
			responsive: true,
			sources: [
				{
					src: 'https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8',
				},
			],
		};

    return (
			<>
      <Navbar />
      <ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
      <ReactTooltip id='custom-color-no-arrow' className='custom-color-no-arrow'  effect='solid'/>
      <Modal isOpen={this.state.modal} toggle={this.toggle} centered backdrop="static">
        	<ModalHeader toggle={this.toggle}>Edit {'>'} {this.state.studios.name}</ModalHeader>
        	<ModalBody>
          <form>
                <div className="form-group">
						      <label className="form-label text-left">Title</label>
						      <input type="text" className="form-control" placeholder="" value={this.state.title} onChange={this.handleChangeTitle}></input>
					      </div>
                <div className="form-group">
						      <label className="form-label text-left">Description</label>
						      <textarea type="text" className="form-control" placeholder="" value={this.state.desc} onChange={this.handleChangeDesc}></textarea>
					      </div>
                <div className="flex-container">
                    
                    <div style={{marginTop: 5+'px', marginRight: 10+'px'}}>
                      <Switch
                        name={"themeTwo"}
                        theme={"two"}
                        checked={this.state.value}
                        onChange={this.handleChangeSwitch}
                      />
                    </div>
                    <span>Viewer: {this.state.value ? <span className="tag-enable">Enabled</span> : <span className="tag-disable">Disabled</span> }</span>
                </div>
            </form>
        	</ModalBody>
          <ModalFooter>
						<div>
							<button className="btn btn-primary" onClick={this.updateChannel} style={{margin: 10+'px'}}>Update</button>
							<button className="btn btn-light" onClick={this.toggle} style={{margin: 10+'px'}}>Close</button>
						</div>
					</ModalFooter>
				</Modal>

        {/* modal confirm */}
				<Modal isOpen={this.state.modalConfirm} toggle={this.toggleConfirm} centered backdrop="static">
        	<ModalHeader toggle={this.modalConfirm}>{this.state.studios.name}</ModalHeader>
        	<ModalBody>
						<div className="form-group text-center">
							<h5>Are you sure you want to stop recording ?</h5>
						</div>
        	</ModalBody>
          <ModalFooter>
						<div>
            <button className="btn btn-danger" onClick={this.stopRecord} style={{margin: 10+'px'}}>Stop</button>
							<button className="btn btn-light" onClick={this.toggleConfirm} style={{margin: 10+'px'}}>Close</button>
						</div>
					</ModalFooter>
				</Modal>
      <div className="container">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{this.state.studios.name}</h3>
          </div>
          <div className="card-body">
            <div className="row" style={{marginBottom: 30+'px'}}>
              <div className="col-md-5 text-center">
              {/* <ReactHlsPlayer
                src={this.state.playerSource}
                autoPlay={true}
                controls={true}
                muted={true}
                width="100%"
                height="auto"
              /> */}

              <div className="video-area">
              
                {/* <Player muted={true} className="vjs-big-play-centered">
                  <div >
                    {this.state.isconnect ? <this.Live /> : ''}
                  </div>
                  
                  <HLSSource isVideoChild id={this.props.match.params.id} typeUrl="Live" src={this.state.playerSource}/>
                </Player> */}
              </div>
              
              {/* <VideoPlayer id="live" liveURL={this.state.playerSource} {... videoJsOptions} /> */}
              <VideoPlayer id="video-area" className="" type={this.state.refreshStream} src={this.state.playerSource} {... videoJsOptions} />
              <div className="table-responsive" style={{paddingBottom: 0+'px'}}>
						<table className="table card-table table-vcenter">
							<thead>
								<tr>
									{/* <th>Descriptions</th> */}
									{/* <th class="text-center" width="100">Recorder</th> */}
									<th className="text-center" >Source</th>
                  <th className="text-center" >Recorder</th>
                  {/* <th className="text-center" width="150">Last Update</th> */}
									<th className="text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									{/* <td>{item.desc}</td> */}
									{/* <td class="text-center">
										<span class="tag tag-green" ng-show="item.option == 'auto'">Auto</span>
										<span class="tag tag-primary" ng-show="item.option == 'manual'">Manual</span>
										<span class="tag tag-red" ng-show="item.option == 'none'">Off</span>
									</td> */}
									<td className="text-center">
										<div className={this.state.isconnect === true ? 'd-block' : 'd-none'}><span style={{color: 'green'}}><FontAwesomeIcon icon={['fas', 'video']} /></span></div>
										<div className={this.state.isconnect === false ? 'd-block' : 'd-none'}><span style={{color: 'red'}}><FontAwesomeIcon icon={['fas', 'video']} /></span></div>
									</td>
                  <td className="text-center">
										<div className={this.state.isrecording === true ? 'd-block' : 'd-none'}><blink><span className="status-icon bg-danger" ></span></blink></div>
										<div className={this.state.isrecording === false ? 'd-block' : 'd-none'}><span style={{color: '#808080'}}><FontAwesomeIcon icon={['fas', 'circle']} /></span></div>
									</td>
                  {/* <td className="text-center">{moment(item.updatedAt).format('yyyy-MM-DD HH:mm')}</td> */}
									<td className="text-center">
                  {this.state.isrecording 
                      ? <button type="button" className="btn btn-secondary" onClick={e => this.recordStream(e, 'false')} disabled={this.state.disable} data-for='custom-color-no-arrow' data-tip='Stop Record'><FontAwesomeIcon icon={['fas', 'stop']} /></button>
                      : <button type="button" className="btn btn-danger" onClick={e => this.recordStream(e, 'true')} disabled={this.state.disable} data-for='custom-color-no-arrow' data-tip='Start Record'><FontAwesomeIcon icon={['fas', 'circle']} /></button>
                    }
                    {/* <button className="btn btn-dark" onClick={e => this.chooseCh(e, item.id)}>Studio</button> */}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
              
              </div>
              <div className="col-md-7">
                <div className="flex-container">
                <form style={{width: 90+'%'}}>
                <div className="form-group">
						      <label className="form-label text-left">Title</label>
						      {/* <input type="text" style={{width: 60+'%'}} className="form-control" placeholder="" value={this.state.title} onChange={this.handleChangeTitle}></input> */}
                  <p>{this.state.title}</p>
					      </div>
                <div className="form-group">
						      <label className="form-label text-left">Description</label>
                  <p>{this.state.desc}</p>
						      {/* <textarea type="text" style={{width: 60+'%'}} className="form-control" placeholder="" value={this.state.desc} onChange={this.handleChangeDesc}></textarea> */}
					      </div>
                  <div className="form-group" >
						        <label className="form-label text-left">Viewers</label>
                    <p>{this.state.viewer}</p>
					        </div>
                <div className="form-group">
                    <div className="flex-container">
                    <span>Viewer: {this.state.valueShow ? <span className="tag-enable">Enabled</span> : <span className="tag-disable">Disabled</span> }</span>
                    </div>  
                  </div>

                {/* <div className="form-group">
                  <div className="form-label float-left" >Recorder Stream</div>
                  <div className="float-left" style={{marginLeft: 30+'px', marginRight: 30+'px'}}>
                    <div className={this.state.isrecording === true ? 'd-block' : 'd-none'}><blink><span className="status-icon bg-danger" ></span></blink></div>
									  <div className={this.state.isrecording === false ? 'd-block' : 'd-none'}><span style={{color: '#808080'}}><FontAwesomeIcon icon={['fas', 'circle']} /></span></div>
                  </div>
                  <div style={{marginTop: -8+'px'}}>
                    {this.state.isrecording 
                      ? <button type="button" className="btn btn-secondary" onClick={e => this.recordStream(e, 'false')} disabled={this.state.disable}><FontAwesomeIcon icon={['fas', 'stop']} /></button>
                      : <button type="button" className="btn btn-danger" onClick={e => this.recordStream(e, 'true')} disabled={this.state.disable}><FontAwesomeIcon icon={['fas', 'circle']} /></button>
                    }
                  </div>
                  
                </div> */}
                </form>
                <div>
                  <button type="button" className="btn btn-secondary" onClick={this.toggle}>Edit</button>
                </div>
                </div>
                
                
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Ingest Stream</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
						              <label className="control-label">Server</label>
						              <div>
							              <div className="flex-container">
  								            <div style={{width: 84+'%'}}><input className="form-control" type="text" id="server" readOnly value={this.state.studios.server} /></div>
  								            <div style={{width: 16+'%', marginLeft: 12+'px', position: 'relative'}}>
                                {this.state.copyServerStatus ? 
                                  <div className="popover-copy">
                                    <i class="arrow down"></i>
                                    <span className="text-copy">Copied !</span>
                                  </div> : ''
                                }
                                <button type="button" onClick={this.serverCopy} className="btn btn-secondary" style={{fontSize: 11+'px'}}>Copy</button>
                                </div>
						              	</div>
						              </div>
					              </div>

                        <div className="form-group">
						              <label className="control-label">Stream Key</label>
						              <div>
							              <div className="flex-container">
  								            <div style={{width: 84+'%'}}><input className="form-control" type="text" id="streamkey_copy" value={this.state.studios.streamkey} readOnly /></div>
  								            <div style={{width: 16+'%', marginLeft: 12+'px', position: 'relative'}}>
                                {this.state.copyKeyStatus ? 
                                  <div className="popover-copy">
                                    <i class="arrow down"></i>
                                    <span className="text-copy">Copied !</span>
                                  </div> : ''
                                }
                                <button type="button" onClick={this.streamkeyCopy} className="btn btn-secondary" style={{fontSize: 11+'px'}}>Copy</button>
                              </div>
						              	</div>
						              </div>
					              </div>

                        <div className="form-group">
						              <label className="control-label">Playback URL</label>
						              <div>
							              <div className="flex-container">
  								            <div style={{width: 84+'%'}}><input className="form-control" type="text" id="restream" readOnly value={this.state.studios.stream_url} /></div>
  								            <div style={{width: 16+'%', marginLeft: 12+'px', position: 'relative'}}>
                                {this.state.copyUrlStatus ? 
                                  <div className="popover-copy">
                                    <i class="arrow down"></i>
                                    <span className="text-copy">Copied !</span>
                                  </div> : ''
                                }
                                <button type="button" onClick={this.playbackCopy} className="btn btn-secondary" style={{fontSize: 11+'px'}}>Copy</button>
                              </div>
						              	</div>
						              </div>
					              </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
						              <label className="control-label">Embed Player</label>
						              <div>
							              <div className="flex-container">
  								            <div style={{width: 84+'%'}}><textarea id="embed" rows="5"  type="text" className="form-control" value={this.state.studios.embed_player} readOnly></textarea></div>
  								            <div style={{width: 16+'%', marginLeft: 12+'px', position: 'relative'}}>
                                {this.state.copyEmbedStatus ? 
                                  <div className="popover-copy">
                                    <i class="arrow down"></i>
                                    <span className="text-copy">Copied !</span>
                                  </div> : ''
                                }
                                <button type="button" onClick={this.embedCopy} className="btn btn-secondary" style={{fontSize: 11+'px'}}>Copy</button>
                              </div>
						              	</div>
						              </div>
					              </div>

                        <div className="flex-container">
                          <div className="form-group" style={{width: 84+'%'}}><button type="button" onClick={this.testPlayer} className="btn btn-secondary float-right" style={{fontSize: 11+'px'}}>Test Player</button></div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <Footer/>
			</>
    );
  }
}

export default Studio

