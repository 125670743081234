import React, { useRef, useState, useEffect } from 'react';
// import VideoPlayer from '../assets/video-js/video'
import VideoPlayer from '../components/LiveTest'
import axios from 'axios';
import config from '../config'

const TestStream = props => {
	console.log(props.status);
	const [status, setStatus] = useState('')

	useEffect(() => {
		
		var pathname = window.location.pathname
		var arr = pathname.split("/")
		axios.post(config.host + '/api/v1/live/stream/detail' , {
			id: arr[2],
			userid: localStorage.StudioUserID
		}).then(res => {
			// setStatus(res)
			if (res.data.result.user_views === 'true') {
				setStatus(true)
			} else {
				setStatus(false)
			}
			// console.log(res.data.result.user_views);
		}).catch(err => {
		
		})
	},[])

	const videoJsOptions = {
		autoplay: true,
		// muted: true,
		controls: true,
		// controlBar: {
		// 	volumePanel: {
		// 		inline: false
		// 	}
		// },
		// fluid: true,
		responsive: true,
		sources: [
			{
				src: 'https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8',
			},
		],
	};
	
  return (
		<>
    	<div className="">
				{/* regkljerklgjreklgjkrejglkerjgk */}
				<VideoPlayer id="video-area" className="" type="test" {... videoJsOptions} />
			</div>
		</>
  )
}

export default TestStream;