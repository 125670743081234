import React from 'react'
import { Router, Route, Switch, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Stream from "./pages/Stream";
import Studio from "./pages/Studio";
import Video from "./pages/Video_library";
import Profile from "./pages/Profile";
import Test1 from "./pages/Test";
import Test2 from "./pages/Test2";
import TestStream from "./pages/TestStream";
import Monitoring from "./pages/Monitoring";
import NotFoundPage from "./pages/NotFoundPage";

const history = createBrowserHistory();

class App extends React.Component {
  render() {
    return (
      <div className="app">
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={withRouter(Login)} />
            {/* <Route path="/home" component={withRouter(Home)} /> */}
            <Route path="/home" component={withRouter(Stream)} />
            <Route path="/live-streams" component={withRouter(Stream)} />
            <Route path="/studio/:id" component={withRouter(Studio)} />
            <Route path="/videos" component={withRouter(Video)} />
            <Route path="/profile" component={withRouter(Profile)} />
            <Route path="/test-stream/:id" component={withRouter(TestStream)} />
            <Route path="/monitoring" component={withRouter(Monitoring)} />
            <Route path="/test1" component={withRouter(Test1)} />
            <Route path="/test2" component={withRouter(Test2)} />
            <Route path="" component={withRouter(NotFoundPage)} />
          </Switch>
        </Router>
      </div>
    )
  }
}
export default App