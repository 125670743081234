import React from 'react';
import videojs from 'video.js'
import '../assets/video-js.css'
import qualitySelector from '../assets/videojs-hls-quality-selector';
// import qualitySelector from 'videojs-max-quality-selector';
import qualityLevels from 'videojs-contrib-quality-levels';
import videoOverlay from 'videojs-dynamic-overlay'
import axios from 'axios';
import config from '../config'
import socketIOClient from 'socket.io-client'

const socket = socketIOClient("https://socket-io.i-iptv.com");


export default class VideoPlayer extends React.Component {

  state = {
    streamName: ''
  }

  componentDidMount() {
    // instantiate Video.js\
    // videojs.registerPlugin('hlsQualitySelector', qualitySelector);\
    videojs.registerPlugin('hlsQualitySelector', qualitySelector);
    videojs.registerPlugin('overlayVideo', videoOverlay);
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      // console.log('onPlayerReady', this)
    });

    var pathname = window.location.pathname
	  var arr = pathname.split("/")
    axios.post(config.host + '/api/v1/live/stream/preview' ,{
      id: arr[2],
      userid: localStorage.StudioUserID
    }).then(res => {
      this.player.src(res.data.stream_url)
      var getStreamURL = res.data.stream_url;
      var smilName = getStreamURL.split('/');
      var streamName = smilName[4].split('.');
      var FinalName = streamName[0].split(':');
      if (FinalName.length === 1) {
        this.setState({
          streamName: FinalName[0]
        })
      } else {
        this.setState({
          streamName: FinalName[1]
        })
      }

      axios.post(config.host + '/api/v1/live/stream/detail' , {
        id: arr[2],
        userid: localStorage.StudioUserID
      }).then(res => {
          console.log(res.data.result.user_views);
        if (res.data.result.user_views === 'true') {
            this.player.overlayVideo({
                contentOfOverlay:"<div style='position: absolute; left: 3%; top: 5%;'><span style='background-color: red; margin-right: 7px; padding: 4px 5px; border-radius: 4px; font-weight: 600;'>LIVE</span> <span class='bg-eye' style='background-color: rgb(30,30,30); margin-right: 7px; padding: 4px 5px; border-radius: 4px;'><i class='fas fa-eye'></i> " + 0 + "</span></div>",
                changeDuration: 3000
            })
              setTimeout(() => {
                socket.on(this.state.streamName, (entry) => {
                // console.log('connected');
                  this.player.overlayVideo({
                    contentOfOverlay:"<div style='position: absolute; left: 3%; top: 5%;'><span style='background-color: red; margin-right: 7px; padding: 4px 5px; border-radius: 4px; font-weight: 600;'>LIVE</span> <span class='bg-eye' style='background-color: rgb(30,30,30); margin-right: 7px; padding: 4px 5px; border-radius: 4px;'><i class='fas fa-eye'></i> " + entry.ccu + "</span></div>",
                    changeDuration: 3000
                  })
                });
              }, 1000);
          } else {
            this.player.overlayVideo({
                contentOfOverlay:"<div style='position: absolute; left: 3%; top: 5%;'><span style='background-color: red; margin-right: 7px; padding: 4px 5px; border-radius: 4px; font-weight: 600;'>LIVE</span></div>",
                changeDuration: 3000
            })
              setTimeout(() => {
                socket.on(this.state.streamName, (entry) => {
                // console.log('connected');
                  this.player.overlayVideo({
                    contentOfOverlay:"<div style='position: absolute; left: 3%; top: 5%;'><span style='background-color: red; margin-right: 7px; padding: 4px 5px; border-radius: 4px; font-weight: 600;'>LIVE</span> </div>",
                    changeDuration: 3000
                  })
                });
              }, 1000);
          }
      }).catch(err => {
  
      })
      
      }).catch(err => {
  
      })

        

        setTimeout(() => {
          socket.on(this.state.streamName, (entry) => {
            if (entry.reload === 'true') {
              console.log('reload');
              var pathname = window.location.pathname
	            var arr = pathname.split("/")
              axios.post(config.host + '/api/v1/live/stream/preview' ,{
                id: arr[2],
                userid: localStorage.StudioUserID
              }).then(res => {
                // console.log(res.data.stream_url);
                this.player.src(res.data.stream_url)
            }).catch(err => {
      
            })
            }
          });
        }, 1000)

    
    this.player.hlsQualitySelector({ 
      displayCurrentQuality: true,
      // 'disableAuto': true,
      // 'showBitrates': true
      // 'filterDuplicates': true
      // defaultResolution: '720p'
      // 'index': 7
    });
    
  }
  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>	
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node } className="video-js vjs-big-play-centered video-live"></video>
        </div>
      </div>
    )
  }
}