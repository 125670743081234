import React from 'react';
import videojs from 'video.js'
import '../assets/video-js.css'
import qualitySelector from '../assets/videojs-hls-quality-selector';
// import qualitySelector from '../assets/videojs-max-quality-selector';
import qualityLevels from 'videojs-contrib-quality-levels';
import videoOverlay from 'videojs-dynamic-overlay'
import axios from 'axios';
import config from '../config'
import socketIOClient from 'socket.io-client'

const socket = socketIOClient("https://socket-io.i-iptv.com");


export default class VideoPlayer extends React.Component {

  state = {
    streamName: ''
  }

  componentDidMount() {
    // instantiate Video.js\
    // videojs.registerPlugin('hlsQualitySelector', qualitySelector);
    videojs.registerPlugin('hlsQualitySelector', qualitySelector);
    videojs.registerPlugin('overlayVideo', videoOverlay);
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      // console.log('onPlayerReady', this)
    });

    if (this.props.type === 'vod') {
      axios.post(config.host + '/api/v1/video/record/preview' ,{
        id: this.props.id,
        userid: localStorage.StudioUserID
      }).then(res => {
        //   console.log(res);
        // https://player.i-iptv.com/#!/hls?type=vod&stream=https%3A%2F%2Fcdn-hwcloud.i-iptv.com%2Fvod%2F1629283035%2Findex.m3u8
        this.player.src('https://cdn-hwcloud.i-iptv.com/vod/1630295641/index.m3u8')
        // this.player.src('https://cdn-edge.i-iptv.com/live/smil:f2fb-c986-1d6b-533f-c3c0.smil/playlist.m3u8')
      }).catch(err => {
  
      })
    }
    
    this.player.hlsQualitySelector({ 
      displayCurrentQuality: true,
      // 'autoLabel': 'ABR',
      // 'showBitrates': true,
      // 'filterDuplicates': true,
      // 'disableAuto': true,
      // 'defaultResolution': '720p',
      // 'index': 7,
    });

    

    
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>	
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node } className="video-js vjs-big-play-centered"></video>
        </div>
      </div>
    )
  }
}