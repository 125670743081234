import React from 'react'
import "../App.css";
import Navbar from "../components/Navbar"
import Footer from '../components/Footer';
import axios from 'axios'
import config from '../config'
import { MDBBtn } from 'mdb-react-ui-kit';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import history from '../history.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const optionToast = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: false,
	draggable: true,
	progress: undefined,
}

class Profile extends React.Component {
  constructor(props) {
    super(props);

		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangePhone = this.handleChangePhone.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this);
		this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);

		this.state = {
			email: '',
			name: '',
			tel: '',
			modal: false,
			valid: false,
			alert: '',
			checkmatch: '',
			password: '',
			newpassword: '',
			confirmpassword: '',
			unmountOnClose: true
		}
  }

  componentDidMount = () => {
    this.feedAccount()
  }

	toggle = () => {
    this.setState({
      modal: !this.state.modal,
			password: '',
			newpassword: '',
			confirmpassword: '',
    })
  }

	handleChangeName(event) {
    this.setState({
      name: event.target.value
    });
  }

	handleChangePhone(event) {
    this.setState({
      tel: event.target.value
    });
  }

	handleChangePassword(event) {
    this.setState({
      password: event.target.value
    });
  }

	handleChangeNewPassword(event) {
    this.setState({
      newpassword: event.target.value
    });
  }

	handleChangeConfirmPassword(event) {
    this.setState({
      confirmpassword: event.target.value
    });
  }

	feedAccount = () => {
		axios.post(config.host + '/api/v1/account' , {
			id: localStorage.StudioUserID
		}).then(res => {
			console.log(res.data.result[0]);
			this.setState({
				email: res.data.result[0].email,
				tel: res.data.result[0].phone,
				name: res.data.result[0].fullname
			})
		}).catch(err => {

		})
	}

	Update = () => {
		axios.post(config.host + '/api/v1/account/update', {
			id: localStorage.StudioUserID,
			fullname: this.state.name,
			phone: this.state.tel
		}).then(res => {
			if (res.data.status === "success") {
				toast.success('Update account successfully !', optionToast);
			}
		}).catch(err => {
			
		})
	}

	ChangePassword = () => {
		// confrim password
		if (this.state.newpassword !== this.state.confirmpassword) {
			this.setState({
				checkmatch: 'Password do not match !',
			})
		} else {
			this.setState({
				checkmatch: '',
			})
			axios.post(config.host + '/api/v1/account/password' , {
				id: localStorage.StudioUserID,
				oldpassword: this.state.password,
				newpassword: this.state.newpassword
			}).then(res => {
				if (res.data.status === 'error') {
					this.setState({
						alert: 'OldPassword is wrong !'
					})
				} else {
					toast.success('Update password successfully !', optionToast);
					this.setState({
						alert: '',
						modal: !this.state.modal
					})
					
				}
			}).catch(err => {

			})
		}
	}


  render() {
    return (
			<>
			<Navbar />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
			/>
			<div className="container" style={{minHeight: '80vh'}}>
			<div className="card">
			 	<div className="card-header">
				 	<h3 className="card-title">Profile</h3>
				</div>
				<div className="card-body">
					<div className="form-group">
						<label className="form-label text-left">Username</label>
						<input type="email" className="form-control" id="email" placeholder="Enter email" value={this.state.email} disabled></input>
					</div>
					<div className="form-group">
						<label className="form-label text-left">Full name</label>
						<input type="text" className="form-control" placeholder="Enter your full name" value={this.state.name} onChange={this.handleChangeName}></input>
					</div>
					<div className="form-group">
						<label className="form-label text-left">Telephone</label>
						<input type="text" className="form-control" placeholder="Enter your telephone number" value={this.state.tel} onChange={this.handleChangePhone}></input>
					</div>

					<div>
					<MDBBtn className='' onClick={this.Update} color='primary' disabled={!this.state.name || !this.state.tel}>Update</MDBBtn>{' '}
					<MDBBtn className='' onClick={this.toggle} color='warning'>Change Password</MDBBtn>
					</div>
				</div>
			</div>

			<div>
      <Modal isOpen={this.state.modal} toggle={this.toggle} centered backdrop="static">
        <ModalHeader toggle={this.toggle}>Change Password</ModalHeader>
        <ModalBody>
					<div className="form-group">
						<label className="form-label text-left">Old Password</label>
						<input type="password" className="form-control" placeholder="Enter your old password" value={this.state.password} onChange={this.handleChangePassword}></input>
						<span style={{color: 'red', fontSize: 10+'px'}}>{this.state.alert}</span>
					</div>
					<div className="form-group">
						<label className="form-label text-left">New Password</label>
						<input type="password" className="form-control" placeholder="New password" value={this.state.newpassword} onChange={this.handleChangeNewPassword}></input>
					</div>
					<div className="form-group">
						<label className="form-label text-left">Confirm New Password</label>
						<input type="password" className="form-control" placeholder="Confirm password" value={this.state.confirmpassword} onChange={this.handleChangeConfirmPassword}></input>
						<span style={{color: 'red', fontSize: 10+'px'}}>{this.state.checkmatch}</span>
					</div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.ChangePassword} disabled={!this.state.password || !this.state.newpassword || !this.state.confirmpassword}>Update</Button>{' '}
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
			</div>
			<Footer />
			</>
    );
  }
}

export default Profile

