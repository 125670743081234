import React, {useState,useEffect} from 'react'
import "../App.css";

const Breadcrumb = (props) => {

	const [id, setId] = useState('');
	const [nameUrl, setNameUrl] = useState([]);


	useEffect(() => {
		var url = props.url
		var id = props.id

		setNameUrl(url)
		setId(id)
	})

	const Slash = () => (
		<span> / </span>
	)

	const switchpage = (e, data) => {

		if (id) {
			if (data === 'Home') {
				//window.location.assign("/home");
				window.location.assign("/live-streams");
			} else if (data === 'Live Streams') {
				window.location.assign("/live-streams");
			} else if (data === 'Videos') {
				window.location.assign("/videos");
			} else if (data === 'Profile') {
				window.location.assign("/profile");
			} else if (data === 'Monitoring') {
				window.location.assign("/monitoring");
			} else {
				window.location.assign("/studio/" + id);
			}
		} else {
			if (data === 'Home') {
				//window.location.assign("/home");
				window.location.assign("/live-streams");
			} else if (data === 'Live Streams') {
				window.location.assign("/live-streams");
			} else if (data === 'Videos') {
				window.location.assign("/videos");
			} else if (data === 'Monitoring') {
				window.location.assign("/monitoring");
			} else if (data === 'Profile') {
				window.location.assign("/profile");
			}
		}
	}

  return (
    <div style={{marginBottom: 20+'px', display: 'block', width: 100+'%', backgroundColor: 'rgb(220,220,220)', paddingLeft: 20+'px', paddingRight: 20+'px'}}>
			{nameUrl.map((item, index) => {
				return <><span key={index} style={{cursor: 'pointer', fontSize: 12+'px', fontWeight: 500}} onClick={e => switchpage(e, item)}>{item}</span> {nameUrl.length === 2 && index - 1 < 0 ? <Slash /> : ''} {nameUrl.length === 3 && index - 1 < 1 ? <Slash /> : ''}</>
			})}
    </div>
  )
}


export default Breadcrumb

