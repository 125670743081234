import React, { useRef, useState, useEffect } from 'react';
import "../App.css";
import Navbar from "../components/Navbar"
import Footer from '../components/Footer';
import Chart from '../components/Chart'

const Monitoring = () => {
	
  return (
		<>
			<Navbar />
    	<div className="container" style={{minHeight: '80vh'}}>
			<div className="card" style={{marginTop: 20+'px', position: 'relative'}}>
					<div className="card-header">
					<h3 className="card-title">Monitoring</h3>
					</div>
					<div className="card-body">
						<Chart />
					</div>
				</div>  
			</div>
			<Footer />
		</>
  )
}

export default Monitoring;