import React, { PureComponent } from 'react'
import "../App.css";
import Navbar from "../components/Navbar"
import Footer from '../components/Footer';
import Highcharts from "highcharts";
// import history from '../history.js'
import axios from 'axios';
import config from '../config'

class Home extends PureComponent {

  instance;

  options = {
    chart: {
      type: "line"
    },
    title: {
      text: "Bar Chart"
    },
    series: [
      {
        data: [1, 3, 4, 10, 9]
      }
    ]
  };
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    this.instance = Highcharts.chart("dummy-id", this.options);
    
  }

  render() {
    return (
			<>
      <Navbar />
      <div className="container" style={{minHeight: '80vh'}}>
        {/* <h1>Dashboard</h1> */}
        <div className="card" style={{marginTop: 20+'px', position: 'relative'}}>
					<div className="card-header">
					<h3 className="card-title">Monitoring</h3>
					</div>
					<div className="card-body">
            <div>
              <div id="dummy-id" />
            </div>
					</div>
				</div>  
        
        <div >
        {/* <iframe style={{width: 100+'%'}} src="https://es-kibana.i-iptv.com/app/kibana#/dashboard/ee9ce980-cda9-11eb-8848-871fd92cfe47?embed=true&_g=(refreshInterval%3A('%24%24hashKey'%3A'object%3A311'%2Cdisplay%3A'1%20minute'%2Cpause%3A!f%2Csection%3A2%2Cvalue%3A60000)%2Ctime%3A(from%3Anow-24h%2Cmode%3Aquick%2Cto%3Anow))" height="600"></iframe> */}
        {/* <iframe style={{width: 100+'%'}} src="https://es-kibana.i-iptv.com/app/kibana#/dashboard/ee9ce980-cda9-11eb-8848-871fd92cfe47?embed=true&_g=()" height="600"></iframe> */}
        {/* <iframe style={{width: 100+'%'}} src="https://es-kibana.i-iptv.com/app/kibana#/dashboard/ee9ce980-cda9-11eb-8848-871fd92cfe47?_g=(refreshInterval%3A('%24%24hashKey'%3A'object%3A698'%2Cdisplay%3A'1%20minute'%2Cpause%3A!f%2Csection%3A2%2Cvalue%3A60000)%2Ctime%3A(from%3Anow-24h%2Cmode%3Aquick%2Cto%3Anow))" height="600"></iframe> */}
        {/* <iframe  src="https://es-kibana.i-iptv.com/app/kibana#/dashboard/ee9ce980-cda9-11eb-8848-871fd92cfe47?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A('%24%24hashKey'%3A'object%3A3346'%2Cdisplay%3A'5%20seconds'%2Cpause%3A!f%2Csection%3A1%2Cvalue%3A5000)%2Ctime%3A(from%3Anow-15m%2Cmode%3Aquick%2Cto%3Anow))" height="600" ></iframe> */}
        </div>
        {/* <Footer /> */}
      </div>
			<Footer/>
			</>
    );
  }
}

export default Home

